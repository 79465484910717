import type { SlideData } from './types';
import { memo, Dispatch, SetStateAction } from 'react';
import { SliderCardMemo } from './slider-card';
import { CardListHeaderMemo } from '../../../cards/card-list-header/card-list-header';
import { CardScrollerMemo } from '../../../cards/card-scroller/card-scroller';
import { BulletArrowDownIcon } from '../../../svg-icons/bullet-arrow-down';
import { useIsomorphicLayoutEffect } from '../../../../hooks/use-isomorphic-layout-effect';
import { restoreTargetScrollPosition } from '../../../../utils/scroll-restoration';
import styles from './slider-nav.module.css';

export type SliderNavProps = {
  slides: SlideData[];
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

export function SliderNav({ slides, setActiveSlideIndex }: SliderNavProps) {
  const hasSlides = !!slides.length;

  // Restore the scroll position of the slider-nav element when the slides
  // are loaded. Note this logic is applied keeping in mind that we use recently
  // played items as the slides here and we load them asynchronously. If that
  // logic changes in the future, this logic might need to be updated.
  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined') return;
    if (!hasSlides) return;
    restoreTargetScrollPosition(window.location.pathname, 'slider-nav');
  }, [hasSlides]);

  return (
    <div className={styles.root}>
      <CardListHeaderMemo title="Recently Played" icon={<BulletArrowDownIcon />} />
      <CardScrollerMemo className={styles.items} scrollerId="slider-nav">
        {slides.map((slide, index) =>
          index === 0 ? null : (
            <SliderCardMemo
              key={index}
              type={slide.type}
              id={slide.id}
              name={slide.name}
              launchUrl={slide.launchUrl}
              imageUrl={slide.imageUrl}
              slideIndex={index}
              setActiveSlideIndex={setActiveSlideIndex}
            />
          ),
        )}
      </CardScrollerMemo>
    </div>
  );
}

export const SliderNavMemo = memo(SliderNav);
