import { memo } from 'react';
import { CardListMemo, CardListItemProps } from '../../../cards/card-list/card-list';
import styles from './trending-card-list.module.css';

export type TrendingCardListItemProps = CardListItemProps;

export type TrendingCardListProps = {
  id: string;
  items: TrendingCardListItemProps[];
};

export function TrendingCardList({ id, items }: TrendingCardListProps) {
  // No items, no render.
  if (!items.length) return null;

  return (
    <div className={styles.root}>
      <CardListMemo
        id={id}
        items={items}
        headerTitle="Trending Now"
        headerSize="large"
        headerIcon={null}
        cardOrientation="portrait"
        cardSize="large"
        showRanking={true}
      />
    </div>
  );
}

export const TrendingCardListMemo = memo(TrendingCardList);
