import { memo, useRef } from 'react';
import Image from 'next/image';
import { AnchorButtonMemo } from '../buttons/button/button';
import { AnchorTagMemo } from '../tag/tag';
import { DetailLinkMemo } from '../detail-link/detail-link';
import { ChevronDownIcon } from '../svg-icons/chevron-down-icon';
import { InternalLinkMemo } from '../internal-link/internal-link';
import { useStore } from '../../providers/store-provider';
import { useCard } from '../../hooks/use-card';
import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_RECT_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
} from '../../utils/nav-system';
import styles from './collection-card.module.css';

export interface CollectionCardProps {
  id: string;
  type: string;
  name: string;
  shortDescription: string;
  launchUrl: string;
  imageUrl: string;
  category?: {
    id: string;
    name: string;
  };
  className?: string;
}

export function CollectionCard({
  type,
  id,
  name,
  shortDescription,
  launchUrl,
  imageUrl,
  category,
  className = '',
}: CollectionCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { cardId, onPointerActivate, onPointerDeactivate, onFocusActivate, onBlurDeactivate } =
    useCard(ref, {
      pointerActivation: null,
    });
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${className}`}
      onPointerDown={onPointerActivate}
      onPointerUp={onPointerActivate}
      onPointerMove={onPointerActivate}
      onPointerEnter={onPointerActivate}
      onPointerLeave={onPointerDeactivate}
      onFocus={onFocusActivate}
      onBlur={onBlurDeactivate}
      data-card-id={cardId}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: 'y',
      }}
    >
      <InternalLinkMemo
        className={styles.imageContainer}
        href={launchUrl}
        target="_blank"
        rel="noreferrer"
        onClick={() => addRecentlyPlayedId(id)}
        tabIndex={-1}
        draggable={false}
      >
        <Image
          key={imageUrl}
          className={styles.image}
          src={imageUrl}
          alt={name}
          draggable={false}
          fill
          quality={85}
          sizes="250px"
        />
        <span className={styles.imageHighlight}></span>
      </InternalLinkMemo>
      <div className={styles.content}>
        <h3 className={styles.title}>
          <span className={styles.titleText}>{name}</span>
          {!!category && (
            <AnchorTagMemo
              className={styles.category}
              name={category.name}
              href={`/category/${category.id}`}
              {...{
                [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
              }}
            />
          )}
        </h3>
        <p className={styles.description}>{shortDescription}</p>
        <div className={styles.actions}>
          <AnchorButtonMemo
            className={`${styles.launchAction} ${ARROW_NAV_TARGET_CLASS}`}
            theme="Primary"
            href={launchUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => addRecentlyPlayedId(id)}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
            }}
          >
            {type === 'game' ? 'Play' : 'Launch'}
          </AnchorButtonMemo>
          <DetailLinkMemo
            className={styles.infoAction}
            theme="SecondaryLight"
            itemType={type}
            itemId={id}
            {...{
              [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
            }}
          >
            <ChevronDownIcon />
          </DetailLinkMemo>
        </div>
      </div>
    </div>
  );
}

export const CollectionCardMemo = memo(CollectionCard);
