import type { GetIndexPageDataQuery } from '../../../../.gql/graphql';
import type { SlideData } from '../slider/types';
import { useMemo } from 'react';
import { useStore } from '../../../../providers/store-provider';

export function useSlides(data: GetIndexPageDataQuery) {
  const recentlyPlayedItems = useStore((state) => state.recentlyPlayedItems);

  const featuredSlide: SlideData | undefined = useMemo(
    () =>
      (data.sliderItems
        ?.filter(({ item }) => item)
        .map((data) => {
          const item = data.item!;
          return {
            id: item.id,
            type: item.type || '',
            name: item.name || '',
            shortDescription: item.shortDescription || '',
            launchUrl: item.launchUrl || '',
            imageUrl: item.landscapeThumbnail?.url || '',
            logoImageUrl: item.logoImage?.url || '',
            logoImageWidth: item.logoImage?.width || 0,
            logoImageHeight: item.logoImage?.height || 0,
            bgImageUrl: item.backgroundImage?.url || '',
            bgVideoUrl: item.backgroundVideo?.url || '',
            categories:
              item.categories?.map((category) => ({
                id: category.id,
                name: category.name || '',
              })) || [],
          };
        }) || [])[0],
    [data],
  );

  const recentlyPlayedSlides = useMemo(
    () =>
      recentlyPlayedItems?.map((item) => {
        return {
          id: item.id,
          type: item.type || '',
          name: item.name || '',
          shortDescription: item.shortDescription || '',
          launchUrl: item.launchUrl || '',
          imageUrl: item.landscapeThumbnail?.url || '',
          logoImageUrl: item.logoImage?.url || '',
          logoImageWidth: item.logoImage?.width || 0,
          logoImageHeight: item.logoImage?.height || 0,
          bgImageUrl: item.backgroundImage?.url || '',
          bgVideoUrl: item.backgroundVideo?.url || '',
          categories:
            item.categories?.map((category) => ({
              id: category.id,
              name: category.name || '',
            })) || [],
        };
      }) || [],
    [recentlyPlayedItems],
  );

  return useMemo(() => {
    const slides = [];
    if (featuredSlide) {
      slides.push(featuredSlide);
      if (recentlyPlayedSlides && recentlyPlayedSlides.length > 0) {
        slides.push(...recentlyPlayedSlides);
      }
    }
    return slides;
  }, [featuredSlide, recentlyPlayedSlides]);
}
